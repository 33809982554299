// @tailwind base;
// @tailwind components;
@tailwind utilities;

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body {
    background-color: #fff;
    font-family: "Rubik", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
}
.container {
    width: 100%;
    // max-width: 1728px;
    // margin: auto;
    // padding: 22px 30px;
    display: flex;
    position: relative;
}
.wrapper {
    margin: auto;
    padding: 0 15px;
    margin-top: 30px;
}
.dis-flex {
    display: flex;
    flex-wrap: wrap;
}
.content-center {
    justify-content: center;
}
.content-between {
    justify-content: space-between;
}
h1 {
    font-family: "Rubik", sans-serif;
    font-size: 20px;
    margin-top: 8px;
    color: #fff;
    @media (max-width:1600px) {
        font-size: 16px;
    }
}
h1,h2,h3 {
    text-transform: capitalize;
    font-weight: 400;
}
.bg-pink {
    background: #FFF5F3 !important;
    color: rgba(0, 0, 0, 0.5) !important;
}
.bg-red-theme {
    background-color: #E53E14;
    color: #fff;
}
.select-default {
    padding: 8px 10px !important;
    appearance: auto !important;
    border: 1px #f3f4f6 solid !important;
    border-radius: 5px;
    color: #000 !important;
    font-size: 15px;
    background: #f3f4f6 !important;
    --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    option {
        background: #fff !important;
        color: #000 !important;
        // font-size: 18px;
        // @media (max-width:1600px) {
        //     font-size: 16px;
        // }
        // @media (max-width:1440px) {
        //     font-size: 14px;
        // }
    }
}
.tox {
    .tox-form {
        label {
            margin-bottom: 5px !important;
            margin-top: 10px !important;
        }
        .tox-textfield {
            // background: #FFF5F3 !important;
            color: rgba(0, 0, 0, 0.5) !important;
        }
    }
    .tox-dialog__footer-end {
        button {
            background-color: #E53E14 !important;
            border-color: #E53E14;
        }
        button.tox-button.tox-button--secondary {
            background-color: #fff !important;
            border-color: #E53E14;
            color: #E53E14;
        }
    }
}

.default-btn-outer {
    display: flex;
    justify-content: space-between;
}
.default-btn {
    background-color: #E53E14;
    border-radius: 8px;
    padding: 10px 20px;
    display: inline-block;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    border: 0;
    outline: 0;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    &.cursor-not-allowed.default-btn {
        cursor: not-allowed;
    }
    svg {
        vertical-align: middle;
    }
    &.view-btn {
        &::before {
            content: '';
            background: url('../src/assets/images/viewDashboardBtn.svg') 0 4px no-repeat;
            background-size: 100%;
            width: 16px;
            height: 16px;
            position: relative;
            display: inline-block;
            margin-right: 10px;
        }
    }
    &.prev-btn,
    &.next-btn {
        background-color: #F3F3F3;
        color: #363538;
        &::before, &::after {
            width: 11px;
            height: 11px;
            position: relative;
            display: inline-block;
            filter: brightness(0.2);
            vertical-align: middle;
        }
    }
    &.prev-btn {
        &::before {
            content: '';
            background: url('../src/assets/images/prevBtn.svg') 0 0 no-repeat;
            background-size: 100%;
            margin-right: 10px;
        }
    }
    &.next-btn {
        &::after {
            content: '';
            background: url('../src/assets/images/prevBtn.svg') 0 0 no-repeat;
            background-size: 100%;
            margin-left: 10px;
            transform: rotate(180deg);
        }
    }
    &.bg-red-btn {
        background-color: #E53E14;
        color: #fff;
        &::before,&::after {
            filter: brightness(1);
        }
    }
    &.cancel {
        background-color: #fff;
        border: 1px #E53E14 solid;
        color: #E53E14;
    }
}
.light-default-btn {
    background-color: #c9f2ff !important;
}
.font-8 {
    font-size: 8px;
}
.font-9 {
    font-size: 9px;
}
.font-10 {
    font-size: 10px;
}
.font-12 {
    font-size: 12px;
}
.font-13 {
    font-size: 13px;
}
.font-14 {
    font-size: 14px;
}
.font-16 {
    font-size: 16px;
    @media (max-width:1600px) {
        font-size: 14px;
    }
}
.font-17 {
    font-size: 17px;
    @media (max-width:1600px) {
        font-size: 14px;
    }
}
.font-25 {
    font-size: 25px;
    @media (max-width:1600px) {
        font-size: 18px;
    }
}
.big-font {
    font-size: 100px;
    @media (max-width:1600px) {
        font-size: 90px;
    }
    @media (max-width:1320px) {
        font-size: 80px;
    }
}
.gold {
    color: #D9A25D;
}
.red {
    color: #F78757;
}
.white {
    color: #fff;
}
.green {
    color: #5BC3A3;
}
.silver {
    color: #D2D2D2;
}
.margin-l-20 {
    margin-left: 20px;
} 
.margin-l-10 {
    margin-left: 10px;
} 
.margin-l-5 {
    margin-left: 5px;
} 
.margin-lr-4 {
    margin: 0 4px;
}
.small-gap {
    margin: 0 2px;
}
select,.select,button,input,textarea {
    font-family: "Rubik", sans-serif;
}
select,button,option {
    cursor: pointer;
    &.cursor-not-allowed.default-btn {
        cursor: not-allowed;
    }
}

// 
.sidebar {
    background-color: #1B1B29;
    max-width: 265px;
    width: 100%;
    min-height: 100vh;
    padding: 28px 0 28px 28px;
    transition: all 0.2s ease-in-out;
    @media (max-width:1600px) {
        max-width: 225px;
        padding: 20px 0 20px 15px;
    }
    @media (max-width:1550px) {
        max-width: 200px;
    }
    @media (max-width:991px) {
        max-width: 85px;
        h1 {
            display: none;
        }
        .logo {
            padding-right: 20px;
            img {
                max-width: 30px !important;
            }
        }
        .menu-title {
            justify-content: center !important;
        }
        .hide-text-m {
            display: none;
        }
        .sub-menu {
            .sub-head {
                padding: 10px 8px;
                padding-left: 16px;
                @media (max-width:991px) {
                    text-align: center;
                }
            }
        }
    }
    &:not(.slide) {
        .logo {
            margin-left: -20px;
            @media (max-width:1600px) {
                margin-left: -10px;
            }
            @media (max-width:991px) {
                margin-left: 10px;
            }
            img {
                max-width: 60px;
                margin-left: -10px;
            }
        }
    }
    .logo {
        text-align: center;
        margin-bottom: 15px;
    }
    .menu {
        .menu-item, .sub-menu {
            color: #fff;
            text-decoration: none;
            font-size: 14px;
            @media (max-width:1600px) {
                font-size: 12px;
            }
        }
        .menu-item {
            position: relative;
            &::before {
                content: '';
                background-color: rgb(240 240 240 / 60%);
                width: 93%;
                height: 1px;
                position: absolute;
                top: 0;
                left: 0;
            }
            &.active {
                .sub-menu {
                    display: block;
                }
                .menu-btn {
                    transform: rotate(180deg);
                }
                .sub-menu-item {
                    .sub-title {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding-right: 20px;
                        .sub-head {
                            width: 100%;
                            &:hover:not(.false), &.active:not(.false) {
                                background-color: transparent;
                                border-right: 0;
                                border-radius: 0;
                            }
                        }
                    }
                    .sub-menu {
                        display: none;
                        margin-bottom: 0;
                    }
                    .menu-btn {
                        cursor: pointer;
                        transform: rotate(0);
                    }
                    &.active {
                        .sub-menu {
                            display: block;
                        }
                        .menu-btn {
                            transform: rotate(180deg);
                        }
                    }
                    
                }
        
            }

        }
        .menu-title {
            display: flex;
            justify-content: space-between;
            padding: 20px 20px 20px 0;
            cursor: pointer;
        }
    }
    .sub-menu {
        margin-bottom: 10px;
        display: none;
        .sub-head {
            padding: 15px 10px;
            cursor: pointer;
            @media (max-width:1600px) {
                padding: 10px 8px;
            }
            &:hover:not(.false), &.active:not(.false) {
                background-color: rgb(255 255 255 / 20%);
                border-right: 4px #E53E14 solid;
                border-radius: 4px;
            }
            &.hidenfun {
                background-color: transparent;
                border-radius: 4px;
                border-right-color: transparent;
                // cursor: not-allowed !important;
                color: #cccccc57;
                pointer-events: none;
                img {
                    opacity: 0.4;
                }
                &:hover {
                    border-right-color: transparent;
                    background-color: transparent;
                    cursor: not-allowed !important;
                }
            }
            img {
                margin-right: 10px;
                vertical-align: middle;
                width: 16px;
                height: 16px;
                @media (max-width:991px) {
                    margin-right: 20px;
                }
            }
            svg {
                @media (max-width:991px) {
                    margin-right: 20px;
                }
            }
        }
        .false {
            cursor: auto;
            opacity: 0.3;
            pointer-events: none;
        }
    }
    &.slide {
        max-width: 95px;
        @media (max-width: 1600px) {
            max-width: 85px;
        }
        h1 {
            display: none;
        }
        .logo {
            padding-right: 20px;
            img {
                max-width: 30px;
            }
        }
        .menu-title {
            justify-content: center;
        }
        .hide-text-m {
            display: none;
        }
        .sub-menu {
            .sub-head {
                padding-left: 16px;
            }
        }
        .menu-item {
            &.active {
                .sub-menu-item {
                    .sub-title {
                        display: none;
                    }
                    .menu-btn {
                        display: none;
                    }
                    .sub-menu {
                        display: block;
                    }
                }
            }
        }
    }
}
.dashboard {
    background-color: #F9F9F9;
    width: 100%;
    position: relative;
    padding-bottom: 70px;
    select,.select {
        background: transparent;
        border: 0;
        outline: 0;
        cursor: pointer;
        background-position: right;
        padding-right: 10px;
        // -moz-appearance:none;
        // -webkit-appearance:none;
        // appearance:none;
        // background: url('../images/down-arr.png') 0 0 no-repeat;
    }
}
.top-header {
    padding: 20px;
    box-shadow: 0px 0px 10px rgb(54 53 56 / 40%);
    background-color: #fff;
    @media (max-width:1600px) {
        padding: 5px 15px;
    }
    h2 {
        font-size: 24px;
        font-weight: 600;
        color: #000C43;
        display: inline-block;
        @media (max-width:1600px) {
            font-size: 20px;
        }
    }
    .hamberger {
        vertical-align: middle;
        margin-right: 10px;
        margin-top: 12px;
        display: inline-block;
        cursor: pointer;
        @media (max-width:991px) {
            display: none;
        }
        span {
            background-color: #33363F;
            width: 20px;
            height: 3px;
            border-radius: 2px;
            display: inline-block;
            &::before,&::after {
                content: '';
                background-color: #33363F;
                height: 3px;
                border-radius: 2px;
                display: inline-block;
                position: relative;
            }
            &::before {
                width: 16px;
                top: -4px;
            }
            &::after {
                width: 10px;
                top: -16px;
            }
        }
    }
    .top-header-inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .right-box {
        display: flex;
        gap: 22px;
        align-items: center;
    }
    .search-box {
        background-color: #EFEFEF;
        border-radius: 5px;
        display: flex;
        padding-left: 12px;
        min-width: 253px;
        input {
            font-size: 12px;
            color: #363538;
            padding: 12px;
            border: 0 !important;
            outline: 0;
            background-color: transparent !important;
            width: 100%;
        }
    }
    .notification-box {
        background-color: #FFECE7;
        border-radius: 5px;
        padding: 9px 11px;
        position: relative;
        .live {
            background-color: #A8F650;
            width: 8px;
            height: 8px;
            position: absolute;
            top: -2px;
            right: 3px;
            border-radius: 50%;
        }
    }
    .admin-box {
        display: flex;
        gap: 9px;
        cursor: pointer;
        img {
            width: 40px;
            height: 40px;
            border-radius: 50px;
            object-fit: cover;
        }
        .name {
            font-size: 16px;
            color: #000;
            text-transform: capitalize;
        }
        .title {
            font-size: 12px;
            margin-top: 5px;
            color: #363538;
        }
        .admin-popup {
            display: none;
            &.active {
                position: absolute;
                background: #fff;
                padding: 10px;
                top: 90px;
                min-width: 170px;
                display: block;
                right: 20px;
                box-shadow: 0px 1px 10px #e1e1e1;
                z-index: 9;
                border-radius: 10px;
                @media (max-width:1600px) {
                    top: 64px;
                }
                li {
                    list-style-type: none;
                }
                svg {
                    width: 18px;
                    vertical-align: middle;
                    margin-right: 3px;
                
                }
            }
        }
    }
}


.column-boxes-section {
    .column-boxes-inner {
        display: flex;
        flex-wrap: wrap;
        gap: 36px;
        .icon-box {
            background-color: #DDFDFF;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 68px;
            height: 68px;
            margin: auto;
            transition: all 0.3s ease-in-out;
        }
        .col-box {
            flex-basis: 25%;
            padding: 24px 14px 18px;
            background-color: #fff;
            border-radius: 10px;
            text-align: center;
            transition: all 0.2s ease-in-out;
            img {
                transition: all 0.4s ease-in-out;
            }
            &:hover {
                .icon-box {
                    transform: scale(1.1);
                    img {
                        transform: scale(1.2);
                    }
                }
            }
            h3 {
                margin-top: 14px;
                font-size: 16px;
                color: #000;
            }
            h6 {
                margin-top: 4px;
                color: rgb(54 53 56 / 50%);
                font-size: 10px;
            }
            .connect-box {
                color: #363538;
                font-size: 14px;
                background-color: #F7F7F7;
                padding: 11px;
                border-radius: 4px;
                margin-top: 20px;
                cursor: pointer;
                transition: all 0.3s ease-in-out;
                img {
                    margin-right: 5px;
                    vertical-align: middle;
                    transition: all 0.3s ease-in-out;
                }
                &:hover {
                    background-color: #E53E14;
                    color: #fff;
                    img {
                        filter: invert(1);
                    }
                }
            }
            .connect {
                .disconnect-icon {
                    display: none;
                }
            }
            .disconnect {
                .connect-icon {
                    display: none;
                }
            }
        }
    }
}
.devices-col-section {
    .col-box {
        transition: all 0.2s ease-in-out;
        .icon-box {
            background-color: #DDFDFF;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 68px;
            height: 68px;
            margin: auto;
            transition: all 0.3s ease-in-out;
        }
        img {
            transition: all 0.4s ease-in-out;
        }
        &:hover {
            .icon-box {
                transform: scale(1.1);
                img {
                    transform: scale(1.2);
                }
            }
        }
    }
    .connect-box {
        color: #363538;
        font-size: 14px;
        background-color: #F7F7F7;
        padding: 11px;
        border-radius: 4px;
        margin-top: 20px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        img {
            margin-right: 5px;
            vertical-align: middle;
            transition: all 0.3s ease-in-out;
        }
        &:hover {
            background-color: #E53E14;
            color: #fff;
            img {
                filter: invert(1);
            }
        }
    }
    .connect {
        .disconnect-icon
         {
            display: none;
        }
    }
    .disconect {
        .connect-icon {
            display: none;
        }
    }

}
.dasboard-footer {
    font-size: 14px;
    color: #000;
    background-color: #F0F0F0;
    padding: 11px;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    text-align: center;
}

// 


.summary-chart-main {
    display: flex;
    justify-content: space-between;
    gap: 33px;
    margin-top: 20px;
    @media (max-width:1200px) {
        gap: 20px;
    }
}
.summary-chart-section {
    text-align: center;
    background-color: #fff;
    padding: 45px 40px;
    flex-basis: 70%;
    border-radius: 10px;
    h3 {
        font-size: 20px;
        font-weight: 400;
    }
    .summary-chart-inner {
        margin-top: 30px;
        display: flex;
        gap: 80px;
        justify-content: center;
        .chart-box {
            .chart {
                min-height: 300px;
            }
            h4 {
                color: #363538;
                font-size: 16px;
                font-weight: 400;
            }
        }
    }
}

.summary-right-box {
    flex-basis: 30%;
    padding: 20px 20px 40px;
    background-color: #fff;
    border-radius: 10px;
    position: relative;
    z-index: 0;
    &.winner-box {
        padding-top: 100px;
        background: linear-gradient(to bottom,  rgba(30,87,153,1) 0%,rgba(255,245,243,1) 0%,rgba(255,255,255,1) 100%);
        &::before {
            content: '';
            background: url('../src/assets/images/popperImg.svg') 0 0 no-repeat;
            background-size: cover;
            width: 100%;
            height: 200px;
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
        }
    }
    .summary-img-box {
        text-align: center;
    }
    .content-head {
        margin-top: 30px;
        max-width: 240px;
        margin-left: auto;
        margin-right: auto;
        h4 {
            font-size: 20px;
            color: #E53E14;
            font-weight: 500;
        }
        p {
            font-size: 12px;
            color: #363538;
            margin-top: 7px;
        }
    }
    .monthly-summary-box {
        padding-right: 15px;
        &::before {
            content: "";
            display: block;
            background: linear-gradient(to right, transparent, #C3C3C3, transparent);
            width: 240px;
            height: 1px;
            position: relative;
            margin: 34px auto;
        }
        h5 {
            font-size: 18px;
            color: #000000;
            font-weight: 400;
            margin-bottom: 20px;
        }
        ul {
            li {
                font-size: 11px;
                color: #363538;
                background-color: #F9F9F9;
                border-radius: 4px;
                padding: 11px;
                margin-top: 15px;
                list-style-type: none;
                display: flex;
                align-items: center;
                .time {
                    font-size: 12px;
                    display: block;
                    margin-top: 6px;
                    font-weight: 500;
                }
                .icon-img {
                    background-color: #FFFFFF;
                    width: 36px;
                    height: 36px;
                    padding: 8px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50px;
                    margin-right: 20px;
                }
            }
        }
    }
}

body .tox-tinymce {
    background: none !important;
}

.membership-section {
    .col-box {
        h3 {
            font-size: 30px;
            font-weight: 600;
            text-transform: lowercase;
            span {
                font-size: 14px;
                color: #363538;
                font-weight: 400;
            }
        }
        h4 {
            font-size: 23px;
            color: #000;
            margin: 20px 0 6px;
            text-transform: capitalize;
        }
        p {
            font-size: 13px;
            color: #363538;
        }
        button {
            width: 100%;
        }
        ul {
            margin-top: 20px;
            li {
                color: #363538;
                font-size: 13px;
                margin-top: 8px;
                list-style-type: none;
                position: relative;
                padding-left: 25px;
                &::before {
                    content: '';
                    background: url('../src/assets/images/redTick.svg') 0 4px no-repeat;
                    background-size: 100%;
                    width: 10px;
                    height: 12px;
                    display: inline-block;
                    border-radius: 50px;
                    position: absolute;
                    top: 0;
                    left: 4px;
                    z-index: 1;
                }
                &::after {
                    content: '';
                    background: #FFF5F3;
                    background-size: 100%;
                    width: 17px;
                    height: 17px;
                    border-radius: 50px;
                    display: inline-block;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
        }
        &.active-plan {
            background-color: #E53E14;
            color: #fff;
            h3 {
                span {
                    color: #fff;
                }
            }
            h4,p,li {
                color: #fff;
            }
    
        }
        .current-plan {
            margin-top: 40px;
            text-align: center;
            h5 {
                color: #fff;
                font-size: 13px;
                font-weight: 600;
            }
            .renew-date {
                font-size: 10px;
                color: #fff;
            }
        }
    }
}
.scale-box {
    transition: all 0.3s ease-in-out;
    &:hover {
        transform: scale(1.2);
    }
}
table {
    thead {
        position: sticky;
        top: 0;
    }
}
// .max-w-4 {
//     max-width: 1rem;
// }
.slick-slider {
    .slick-track {
        display: flex;
        gap: 20px;
    }
    .slick-list {
        max-width: 1200px;
        margin: auto;
        @media (max-width:1600px) {
            max-width: 1000px;
        }
        @media (max-width:1320px) {
            max-width: 800px;
        }
        @media (max-width:1150px) {
            max-width: 700px;
        }
    }
    .slick-slide {
        gap: 20px;
    }
}
.bulk-input {
    border: 1px rgb(27 27 41 / 20%) solid;padding: 40px;text-wrap: wrap;height: 200px;width: 100%;display: flex;align-items: center;justify-content: center;border-radius: 10px;cursor: pointer;text-decoration: underline;
    input {
        border: 0 !important; 
    }
}
.edit-pop-up {
    position: absolute !important;
    top: 0;
    left: 0;
    right: 0;
    background: rgb(0 0 0 / 60%);
    height: 100%;
}

.edit-user-page {
    .input-after {
        position: absolute;
        top: 42px;
        right: 20px;
        color: #999c;
        font-size: 14px;
    }
}

.content-block-section {
    ul {
        padding-left: 20px;
        margin-top: 10px;
        li {
            margin-top: 5px;
        }
    }
    h2 {
        font-size: 20px;
        margin-top: 20px;
    }
    p {
        margin-top: 10px;
        font-size: 16px;
    }
}

.col-package-section {
    .col-package {
        &:nth-child(2) {
            .title-box {
                background-color: #2995F7;
            }
        }
        &:nth-child(3) {
            .title-box {
                background-color: #5348F3;
            }
        }
        &:nth-child(4) {
            .title-box {
                background-color: #F12292;
            }
        }
        &:nth-child(5) {
            .title-box {
                background-color: #E8445D;
            }
        }
        &:nth-child(7) {
            .title-box {
                background-color: #2995F7;
            }
        }
        &:nth-child(8) {
            .title-box {
                background-color: #5348F3;
            }
        }
        &:nth-child(9) {
            .title-box {
                background-color: #F12292;
            }
        }
        &:nth-child(10) {
            .title-box {
                background-color: #E8445D;
            }
        }
        &:hover {
            .buy-button {
                background-color: #E53E14;
                color: #fff;
            }
        }
    }
}